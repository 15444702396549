import useSWRImmutable from "swr/immutable";
import swrOnError from "../configs/swrOnError";
import http from "../http_clients/designtinkers";
import { Country } from "../types/Country";

export function useCountries() {
  const { data, error } = useSWRImmutable<Country[]>(
    process.env.NEXT_PUBLIC_API_URL + "/api/countries",
    (url) => http.get(url).then((res) => res.data.data),
    swrOnError
  );

  return {
    countries: data,
    isLoading: !error && !data,
    isError: error,
  };
}
