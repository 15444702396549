// import Swiper core and required modules
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { useRef } from "react";
import { Event } from "../types/Event";
import { EventItem } from "./EventItem";
import { SwiperNextGreen, SwiperPrevGreen } from "./layouts/SliderArrows";

interface EventSliderProps {
  events: Event[];
}

export const EventSlider = ({ events }: EventSliderProps) => {
  return (
    <div className="relative -mx-5">
      <Swiper
        modules={[Navigation]}
        slidesPerView={1}
        breakpoints={{
          768: {
            slidesPerView: 2,
          },
          1100: {
            slidesPerView: 3,
          },
        }}
        navigation={{
          prevEl: ".event-prev-button",
          nextEl: ".event-next-button",
        }}
        onSlidesLengthChange={(swiper: any) => {
          if (swiper.slides.length <= 3) {
            swiper.wrapperEl.classList.add("md:!justify-center");
          } else {
            swiper.wrapperEl.classList.add("md:!justify-start");
          }
          if (swiper.slides.length == 1) {
            swiper.wrapperEl.classList.add("!justify-center");
          } else {
            swiper.wrapperEl.classList.add("!justify-start");
          }
        }}
        className="relative h-full justify-center !pl-1.5 !pr-12"
      >
        {events.map((event) => (
          <SwiperSlide key={event.id} className="pb-10 px-4">
            <EventItem event={event} />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="event-prev-button absolute top-1/2 -translate-y-full z-20 left-2 lg:-left-5 xl:-left-12">
        <SwiperPrevGreen />
      </div>
      <div className="event-next-button absolute top-1/2 -translate-y-full z-20 right-2 lg:-right-5 xl:-right-12">
        <SwiperNextGreen />
      </div>
    </div>
  );
};
