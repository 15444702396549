import { DateTime } from "luxon";
import { Schedule } from "../../types/Schedule";

type OnSelect = (schedule: Schedule) => void;

interface OtherAvailableDatesProps {
  schedules: Schedule[];
  selectedSchedule: Schedule | undefined;
  onSelect?: OnSelect;
}

export const OtherAvailableDates = ({
  schedules,
  selectedSchedule,
  onSelect,
}: OtherAvailableDatesProps) => {
  const isSameMonth = (schedule: Schedule) => {
    return (
      DateTime.fromISO(schedule.start_date).monthShort.toUpperCase() ===
      DateTime.fromISO(schedule.end_date).monthShort.toUpperCase()
    );
  };

  const isSameDate = (schedule: Schedule) => {
    return (
      DateTime.fromISO(schedule.start_date).day ===
      DateTime.fromISO(schedule.end_date).day
    );
  };

  return (
    <>
      <div className="flex items-center gap-1 mt-2">
        <div className="flex-grow h-px bg-gray"></div>
        <h3 className="text-xs text-gray1 font-medium">
          Other Available Dates
        </h3>
        <div className="flex-grow h-px bg-gray"></div>
      </div>
      <div
        className={`custom-scroll flex flex-row items-center justify-center gap-2 py-2 border-b border-gray overflow-x-auto ${
          schedules.length > 4 && "!justify-start"
        }`}
      >
        {schedules
          .filter((schedule) => schedule.id !== selectedSchedule?.id)
          .map((schedule) => (
            <div
              key={schedule.id}
              onClick={() =>
                onSelect === undefined ? null : onSelect(schedule)
              }
              className={`${onSelect === undefined ? "" : "cursor-pointer"} ${
                schedule.is_sold_out
                  ? "bg-gray text-white"
                  : "bg-purple text-white"
              }  rounded-lg flex-shrink-0 !w-[4.5rem] px-3 py-1.5 text-center`}
            >
              <div
                className={`flex items-center w-full font-medium whitespace-nowrap ${
                  isSameMonth(schedule)
                    ? "justify-center text-xs leading-none"
                    : "justify-between text-[9px] leading-none"
                } `}
              >
                <div>
                  {DateTime.fromISO(
                    schedule.start_date
                  ).monthShort.toUpperCase()}
                </div>
                {!isSameMonth(schedule) && (
                  <div>
                    {DateTime.fromISO(
                      schedule.end_date
                    ).monthShort.toUpperCase()}
                  </div>
                )}
              </div>
              <div className="block text-sm font-semibold whitespace-nowrap text-center">
                {DateTime.fromISO(schedule.start_date).day}
                {(!isSameMonth(schedule) ||
                  (isSameMonth(schedule) && !isSameDate(schedule))) &&
                  ` - ${DateTime.fromISO(schedule.end_date).day}`}
              </div>
            </div>
          ))}
      </div>
    </>
  );
};
