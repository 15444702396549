import { Field, Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import DesignTinkersService from "../../services/DesignTinkersService";

export interface MailingListFormValues {
  email: string;
}

export enum Theme {
  Footer,
  EventDetail,
}

interface MailingListProps {
  theme: Theme;
}

export const MailingList = ({ theme }: MailingListProps) => {
  const [isSubmitted, setIsSubmitted] = useState(0);
  const [responseMessage, setResponseMessage] = useState(null);
  const postMailingList = async (values: MailingListFormValues) => {
    const resp = await DesignTinkersService.postMailingList(values).catch(
      (error: unknown) => {
        setIsSubmitted(-1);
        return Promise.reject(error);
      }
    );

    setResponseMessage(resp.data.data);

    setIsSubmitted(1);
  };

  return (
    <div className="mb-5 mt-12" id="mailing-list-form">
      <h3
        className={` text-green1 text-sm md:text-xl font-semibold mb-4 ${
          theme === Theme.EventDetail && "pl-3"
        }`}
      >
        Sign up our mailing list today!
      </h3>
      <Formik
        initialValues={{
          email: "",
        }}
        validationSchema={Yup.object({
          email: Yup.string().email().required("Email is required"),
        })}
        onSubmit={async (values, actions) => {
          await postMailingList(values);
        }}
      >
        {({ isSubmitting, errors, touched }) => (
          <Form onChange={() => setIsSubmitted(0)}>
            <div
              className={`relative ${theme === Theme.EventDetail && "px-3"}`}
            >
              <div
                className={`relative w-full ${
                  theme === Theme.Footer && "lg:w-9/12"
                }`}
              >
                <Field
                  className={`rounded-md w-full box-border p-3 text-sm ${
                    theme === Theme.EventDetail
                      ? "border-2 border-r-none border-green1"
                      : ""
                  }`}
                  type="email"
                  name="email"
                  placeholder="Enter your e-mail"
                />
                <button
                  className="py-2 px-6 rounded-r-md text-center text-white text-sm bg-green1 absolute right-0 top-0 bottom-0"
                  type="submit"
                >
                  {isSubmitting ? "Submitting.." : "Submit"}
                </button>
              </div>
              {errors.email && touched.email ? (
                <span className="text-red-500 error text-xs">
                  {errors.email}
                </span>
              ) : null}
              {isSubmitted === -1 && (
                <div className="text-red-500 font-bold">
                  Something went wrong, please try again.
                </div>
              )}
              {isSubmitted === 1 && (
                <span className="text-green1 text-xs">{responseMessage}</span>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
