// @ts-nocheck
const swrOnError = {
  onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
    // Never retry on 404.
    if (error.status === 404) return;

    // Never retry for a specific key.
    // if (key === "/api/user") return;

    // Only retry up to 10 times.
    if (retryCount >= 10) return;

    // Retry after 1 second.
    setTimeout(() => revalidate({ retryCount }), 1000);
  },
};

export default swrOnError;
