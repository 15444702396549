import { DateTime } from "luxon";
import Image from "next/image";
import Link from "next/link";
import { useEffect, useState } from "react";
import { Event } from "../types/Event";
import { Schedule } from "../types/Schedule";
import { OtherAvailableDates } from "./Event/OtherAvailableDates";

interface EventItemProps {
  event: Event;
}

export const EventItem = ({ event }: EventItemProps) => {
  let startDate;
  let endDate;
  const [selectedSchedule, setSelectedSchedule] = useState<Schedule>();
  const [isSameMonth, setIsSameMonth] = useState(false);
  const [isSameDate, setIsSameDate] = useState(false);

  useEffect(() => {
    if (event.schedules?.length > 0) {
      selectSchedule(event.schedules[0]);
    }
  }, []);

  const selectSchedule = (schedule: Schedule) => {
    setSelectedSchedule(schedule);
    startDate = DateTime.fromISO(schedule.start_date);
    endDate = DateTime.fromISO(schedule.end_date);
    setIsSameMonth(
      startDate.monthShort.toUpperCase() === endDate.monthShort.toUpperCase()
    );
    setIsSameDate(startDate.day === endDate.day);
  };

  return (
    <div className="flex flex-col justify-between bg-white shadow-[-2px_8px_18px_rgba(0,0,0,0.18)] rounded-md overflow-hidden h-full">
      <div className="flex flex-col flex-grow">
        <div className="w-full aspect-[400/300] relative">
          <Image
            className="w-full h-full object-cover"
            src={event.cover_image_400_300}
            alt={event.name}
            layout="fill"
          />
        </div>
        <div className="p-3 flex-grow flex flex-col">
          <div className="flex items-center gap-3">
            <div className={` ${selectedSchedule?.is_sold_out ?'bg-gray' : 'bg-purple'} rounded-md flex-shrink-0 w-[6.8rem] px-2.5 py-5 text-white text-center`}>
              {selectedSchedule && (
                <>
                  <div
                    className={`flex items-center ${
                      isSameMonth
                        ? "justify-center text-sm md:text-base"
                        : "justify-between text-xs md:text-sm"
                    } px-3 md:px-2 w-full font-medium whitespace-nowrap`}
                  >
                    <div>
                      {DateTime.fromISO(
                        selectedSchedule.start_date
                      ).monthShort.toUpperCase()}
                    </div>
                    {isSameMonth || (
                      <div>
                        {DateTime.fromISO(
                          selectedSchedule.end_date
                        ).monthShort.toUpperCase()}
                      </div>
                    )}
                  </div>
                  <div className="block text-xl md:text-2xl font-semibold whitespace-nowrap mt-0.5">
                    {DateTime.fromISO(
                      DateTime.fromISO(selectedSchedule.start_date).toString()
                    ).day < 10
                      ? "0"
                      : ""}
                    {
                      DateTime.fromISO(
                        DateTime.fromISO(selectedSchedule.start_date).toString()
                      ).day
                    }
                    {(!isSameMonth || (isSameMonth && !isSameDate)) && (
                      <>
                        {" - "}
                        {DateTime.fromISO(
                          DateTime.fromISO(selectedSchedule.end_date).toString()
                        ).day < 10
                          ? "0"
                          : ""}
                        {
                          DateTime.fromISO(
                            DateTime.fromISO(
                              selectedSchedule.end_date
                            ).toString()
                          ).day
                        }
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col">
              <div className="text-xs md:text-sm font-medium text-start mb-1">
                <h3 className="text-gray1 text-[11px] md:text-xs">Venue: </h3>
                <p>{event.venue.name}</p>
              </div>
              <div className="text-xs md:text-sm font-medium text-start">
                <h3 className="text-gray1 text-[11px] md:text-xs">Time: </h3>
                <p>
                  {selectedSchedule?.time_start} - {selectedSchedule?.time_end}
                </p>
              </div>
            </div>
          </div>
          {event.schedules?.length > 1 ? (
            <OtherAvailableDates
              schedules={event.schedules}
              selectedSchedule={selectedSchedule}
              onSelect={(schedule: Schedule) => selectSchedule(schedule)}
            />
          ) : (
            <div className="border-b border-gray pt-[1.25rem]"></div>
          )}
          <div className="flex-grow pt-5 pb-3 px-2 flex flex-col items-center justify-center text-center gap-y-5">
            <h2 className="text-green1 font-bold text-lg md:text-xl line-clamp-2">
              {event.name}
            </h2>
            <div
              dangerouslySetInnerHTML={{ __html: event.short_description }}
              className="text-sm text-gray1 line-clamp-3"
            ></div>
          </div>
        </div>
      </div>
      <div className="p-3">
        <Link href={`/event/${event.slug}`}>
          <div className="cursor-pointer block text-center text-base md:text-lg bg-green4 px-6 py-3 rounded-lg text-white font-medium hover:bg-opacity-90 transition">
            View Event Detail
          </div>
        </Link>
      </div>
    </div>
  );
};
